// 
// Web LM player
//
// args.application: application id
// args.token: user token
// args.courseid: course id
// args.config: application configuration
vimas.sco.webplayer = function (args) {

    var self = this;

    var _scos = [];
    var _sco;
    var _current;
    var _last;

    function vmLoad(course, progress, lastPage, attempt) {

        if (!course.scos) {

            // trigger the access denied
            $(self).trigger('access_denied');

            return $.when();

        } else {

            vimas.sco.lms.attempt = attempt;
            vmInitLMS(course.scos, progress);

            course.id = args.courseid;
            course.skin = JSON.parse(course.skin);

            // update activities configuration
            var scos = vmPrepare({
                application: args.application,
                token: args.token,
                session: args.session,
                config: args.config,
                course: course,
                lang: args.lang,
                course_lang: args.course_lang || 'en'
            });

            // trigger prepared event
            // last chanse to modify configuration
            // before actual load
            $(self).trigger('prepared', { scos: scos });

            // preload activities
            // from selected course
            var defaults = args.config.sco_defaults;
            var preload = vimas.loader.preload({
                bucket: defaults.bucket_script_url || defaults.bucket_root_url,
                config: defaults.idevice_config_file_name,
                skin: course.skin,
                lang: args.lang,
                scos: scos
            });

            // get first page to open on load depending on
            // course mode
            // activity states
            // last opened page
            var first = vmGetFirstPage(progress, scos, {
                token: args.token,
                course: course
            }, lastPage);

            // save list of scos        
            _scos = scos;

            // when preloading is finished
            // check user progress
            return preload.then(function () {

                return first.then(function (sco) {

                    let info = {attemptId: vimas.sco.lms.attempt, sco: sco, course: args.courseid};
                    $(self).trigger('webplayer.start', info);

                    // load sco
                    vmLoadSco(sco.id);
                });
            });
        }
    };

    function vmLoadForTask(course, progress, lastPage, taskId, attempt) {

        if (!course.scos) {

            // trigger the access denied
            $(self).trigger('access_denied');

            return $.when();

        } else {
           
            vimas.sco.lms.attempt = attempt;
            vmInitLMSForTask(course.scos, progress, taskId);

            course.id = args.courseid;
            course.skin = JSON.parse(course.skin);

            // update activities configuration
            var scos = vmPrepare({
                application: args.application,
                token: args.token,
                session: args.session,
                config: args.config,
                course: course,
                lang: args.lang,
                course_lang: args.course_lang || 'en'
            });

            // trigger prepared event
            // last chanse to modify configuration
            // before actual load
            $(self).trigger('prepared', { scos: scos });

            // preload activities
            // from selected course
            var defaults = args.config.sco_defaults;
            var preload = vimas.loader.preload({
                bucket: defaults.bucket_script_url || defaults.bucket_root_url,
                config: defaults.idevice_config_file_name,
                skin: course.skin,
                lang: args.lang,
                scos: scos
            });

            // get first page to open on load depending on
            // course mode
            // activity states
            // last opened page
            var first = vmGetFirstPageForTask(progress, scos, {
                token: args.token,
                course: course
            }, lastPage, taskId);

            // save list of scos        
            _scos = scos;

            // when preloading is finished
            // check user progress
            return preload.then(function () {

                return first.then(function (sco) {

                    let info = {attemptId: vimas.sco.lms.attempt, taskId: taskId, sco: sco, course: args.courseid};

                    $(self).trigger('webplayer.start', info);

                    // load sco
                    vmLoadSco(sco.id);
                });
            });
        }
    };

    function vmInitLMSForTask(scos, progress, taskId) {

        // user / course data
        vimas.sco.lms.token = args.token;
        vimas.sco.lms.session = args.session;
        vimas.sco.lms.courseid = args.courseid;
        vimas.sco.lms.roles = args.roles;

        // save API's
        vimas.sco.lms.url.progress = args.config.api.progress;
        vimas.sco.lms.url.track = args.config.api.track;
        vimas.sco.lms.url.log = args.config.api.log;

        // navigation methods
        vimas.sco.lms.vmNext = vmNext;
        vimas.sco.lms.vmFinish = vmNext;
        vimas.sco.lms.vmPrevious = vmPrevious;
        vimas.sco.lms.vmExit = vmExit;
        vimas.sco.lms.finish = vmFinish;

        var tracks;
        if (!progress || progress.length == 0) {

            tracks = scos.reduce(function (tracks, sco, index) {

                var params = JSON.parse(sco.params);
                var taskAttemptNumber1 = vimas.sco.lms.attempt;
                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: taskAttemptNumber1,
                    key: 'cmi.vms_absolute_score',
                    value: null
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout || '',
                    order: index,
                    attempt: taskAttemptNumber1,
                    key: 'cmi.core.lesson_status',
                    value: null
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: taskAttemptNumber1,
                    key: 'cmi.vms_quiz_xml',
                    value: null
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);

        } else {

            tracks = progress.reduce(function (tracks, item) {

                var sco = scos.find(x => x.id == item.id);
                var params = JSON.parse(sco.params);

                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.vms_absolute_score',
                    value: (item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score') ? 
                    item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score').value : null)
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.core.lesson_status',
                    value: item.status
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.vms_quiz_xml',
                    value: item.details
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);
        };

        vimas.sco.lms.tracksCache(tracks);
    };

    function vmInitLMS(scos, progress) {

        // user / course data
        vimas.sco.lms.token = args.token;
        vimas.sco.lms.session = args.session;
        vimas.sco.lms.courseid = args.courseid;
        vimas.sco.lms.roles = args.roles;

        // save API's
        vimas.sco.lms.url.progress = args.config.api.progress;
        vimas.sco.lms.url.track = args.config.api.track;
        vimas.sco.lms.url.log = args.config.api.log;

        // navigation methods
        vimas.sco.lms.vmNext = vmNext;
        vimas.sco.lms.vmFinish = vmNext;
        vimas.sco.lms.vmPrevious = vmPrevious;
        vimas.sco.lms.vmExit = vmExit;
        vimas.sco.lms.finish = vmFinish;

        var tracks;
        if (!progress || progress.length == 0) {

            tracks = scos.reduce(function (tracks, sco, index) {

                var params = JSON.parse(sco.params);

                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: vimas.sco.lms.attempt,
                    key: 'cmi.vms_absolute_score',
                    value: null
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout || '',
                    order: index,
                    attempt: vimas.sco.lms.attempt,
                    key: 'cmi.core.lesson_status',
                    value: null
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: index,
                    attempt: vimas.sco.lms.attempt,
                    key: 'cmi.vms_quiz_xml',
                    value: null
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);

        } else {

            tracks = progress.reduce(function (tracks, item) {

                var sco = scos.find(x => x.id == item.id);
                var params = JSON.parse(sco.params);

                var score = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.vms_absolute_score',
                    value: (item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score') ? 
                    item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score').value : null)
                };

                var status = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.core.lesson_status',
                    value: item.status
                };

                var details = {
                    activity: sco.id,
                    type: params.idevice_type,
                    content: params.title_id,
                    name: params.sco_name,
                    passage_type: params.passage_type,
                    layout: params.layout,
                    order: item.order,
                    attempt: item.attempt,
                    key: 'cmi.vms_quiz_xml',
                    value: item.details
                };

                tracks.push(score);
                tracks.push(status);
                tracks.push(details);

                return tracks;

            }, []);
        };

        vimas.sco.lms.tracksCache(tracks);
    };

    // prepare activites configuration
    function vmPrepare(args) {

        var config = $.extend({}, args.config.sco_defaults, args.course.skin);
        config.lang = args.lang;
        config.course_lang = args.course_lang

        // prepare scos array
        // update container and course level parameters
        var scos = args.course.scos.map(function (item) {

            item.container = '#sco_' + item.id;
            item.isactivity = true;

            var params = JSON.parse(item.params);
            params = $.extend({}, config, params);
            params.ss_model = args.course.ss_model;
            params.course_mode = args.course.mode;
            params.props = item.props ? JSON.parse(item.props) : null;

            item.params = params;

            return item;
        });

        // call imlpementation specific 
        // for normal and audio modes
        // preparation functions
        scos = self.prepare(scos, args);

        // set application and user paramters
        scos.map(function (item) {

            item.params.application_id = args.application;
            item.params.user_id = args.token;

            return item;
        });

        return scos;
    };

    function vmgGetAttemptFromTaskAttemptNumber(taskAttemptNumber, taskMultiplier) {
        return taskAttemptNumber % taskMultiplier;
    }

    // get first page to open
    function vmGetFirstPageForTask(progress, scos, args, lastPage, taskId) {

        if (args.course.mode == 'assessment') {

            // find last attempted page
            var last = vmGetLastAttemptedPageForTask(progress, args.course.max_attempts, args.course.mode, taskId);

            if (last.id < 0) {

                // no page to open
                // return rejected value
                return $.Deferred().reject();

            } else if (!last.id) {

                // if no last page
                // then it's new attempt
                // so open first page
                return $.when(scos[0]);

            } else {

                // if assessment course
                // then open last unused ( current by progress ) page                
                return vmGetNextIncompleteForTask(progress, scos, last.id, last.attempt);
            };

        } else {

            // get max attempt         
            var taskAttemptNumber = vimas.sco.lms.attempt;

            // fro remediation course
            // there will be only 1 attempt
            // vimas.sco.lms.attempt = 1;

            var completed = progress.filter(x => x.attempt == taskAttemptNumber).every(vmIsActivityCompleted);

            if (completed) {               

                var $tracks;

                var uniq = [...new Map(progress.map(item => [item.id, item])).values()];
                const newArray = uniq.map(a => Object.assign({}, a));
                newArray.forEach((x) => {
                    x.attempt = taskAttemptNumber;
                    x.score = null;
                    x.status = null;
                    x.cmi_params = [];

                    progress.push(x);
                });
                if (!progress || progress.length == 0) {

                    $tracks = scos.reduce(function (tracks, sco, index) {

                        var params = sco.params;
                        var taskAttemptNumber1 = vimas.sco.lms.attempt;

                        var score = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: index,
                            attempt: taskAttemptNumber1,
                            key: 'cmi.vms_absolute_score',
                            value: null
                        };

                        var status = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout || '',
                            order: index,
                            attempt: taskAttemptNumber1,
                            key: 'cmi.core.lesson_status',
                            value: null
                        };

                        var details = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: index,
                            attempt: taskAttemptNumber1,
                            key: 'cmi.vms_quiz_xml',
                            value: null
                        };

                        tracks.push(score);
                        tracks.push(status);
                        tracks.push(details);

                        return tracks;

                    }, []);
                } else {
                    $tracks = progress.reduce(function (tracks, item) {

                        var sco = scos.find(x => x.id == item.id);
                        var params = sco.params;

                        var score = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.vms_absolute_score',
                            value: (item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score') ? 
                            item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score').value : null)
                        };

                        var status = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.core.lesson_status',
                            value: item.status
                        };

                        var details = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.vms_quiz_xml',
                            value: item.details
                        };

                        tracks.push(score);
                        tracks.push(status);
                        tracks.push(details);

                        return tracks;

                    }, []);
                }

                vimas.sco.lms.tracksCache($tracks);
            }

            // find page by last id
            var last = vmGetLastOpenedPageForTask(scos, lastPage);

            return $.when(last);
        };

        function vmGetLastAttemptedPageForTask(progress, max_attempts, taskId) {

            var attempt = vmgGetAttemptFromTaskAttemptNumber(vimas.sco.lms.attempt);

            // check if all items in last attempt is completed
            var completed = progress.filter(x => x.attempt == vimas.sco.lms.attempt)
                .every(vmIsActivityCompleted);

            // if all activities are completed
            // than start new attempt
          /*  if (completed) {
                attempt++;
            }*/

            // don't allow attempt
            // over maximum value
            if (max_attempts && attempt > max_attempts) {

                // trigger the no atempts event
                $(self).trigger('no_attempts', max_attempts);

                return {
                    attempt: vimas.sco.lms.attempt,
                    id: -1
                };

            } else if (completed) {

                return {
                    attempt: vimas.sco.lms.attempt,
                    id: null
                };

            } else {

                // inside max attempt
                // find activity
                // which belongs to latest attempt
                // and is not "completed"
                var last = progress.filter(x => x.attempt == vimas.sco.lms.attempt)
                    .filter(x => x.timemodified)
                    .sort((a, b) => b.timemodified - a.timemodified)
                    .shift();

                return {
                    attempt: vimas.sco.lms.attempt,
                    id: last ? last.id : null
                };
            };
        };

        function vmGetLastOpenedPageForTask(scos, lastPage) {

            // try to get sco from list                                    
            var sco = scos.find(x => x.id == lastPage);

            // if not found or finish activity
            // than open first page
            sco = self.lastUsePage(sco, scos);

            return sco;
        };

        function vmGetNextIncompleteForTask(progress, scos, id, attempt) {

            var sco = scos.find(x => x.id == id);
            var scoProgress = progress.find(x => x.id == id && x.attempt == attempt);

            if (vmIsActivityCompleted(scoProgress)) {

                return vmNextCalculate(sco, scos)
                    .then((item) => item ? vmGetNextIncompleteForTask(progress, scos, item.id, attempt) : sco);

            } else {

                return $.when(sco);
            };
        };
    };

    // get first page to open
    function vmGetFirstPage(progress, scos, args, lastPage) {

        if (args.course.mode == 'assessment') {

            // find last attempted page
            var last = vmGetLastAttemptedPage(progress, args.course.max_attempts, args.course.mode);

            if (last.id < 0) {

                // no page to open
                // return rejected value
                return $.Deferred().reject();

            } else if (!last.id) {

                // if no last page
                // then it's new attempt
                // so open first page
                return $.when(scos[0]);

            } else {

                // if assessment course
                // then open last unused ( current by progress ) page                
                return vmGetNextIncomplete(progress, scos, last.id, last.attempt);
            };

        } else {

            var completed = progress.filter(x => x.attempt == vimas.sco.lms.attempt).every(vmIsActivityCompleted);

            // if all activities are completed
            // than start new attempt
            if (completed) {
              
                var $tracks;

                var uniq = [...new Map(progress.map(item => [item.id, item])).values()];
                const newArray = uniq.map(a => Object.assign({}, a));
                newArray.forEach((x) => {
                    x.attempt = vimas.sco.lms.attempt;
                    x.score = null;
                    x.status = null;
                    x.details = null;
                    x.cmi_params = [];

                    progress.push(x);
                });
                if (!progress || progress.length == 0) {

                    $tracks = scos.reduce(function (tracks, sco, index) {

                        var params = sco.params;

                        var score = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: index,
                            attempt: vimas.sco.lms.attempt,
                            key: 'cmi.vms_absolute_score',
                            value: null
                        };

                        var status = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout || '',
                            order: index,
                            attempt: vimas.sco.lms.attempt,
                            key: 'cmi.core.lesson_status',
                            value: null
                        };

                        var details = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: index,
                            attempt: vimas.sco.lms.attempt,
                            key: 'cmi.vms_quiz_xml',
                            value: null
                        };

                        tracks.push(score);
                        tracks.push(status);
                        tracks.push(details);

                        return tracks;

                    }, []);
                } else {
                    $tracks = progress.reduce(function (tracks, item) {

                        var sco = scos.find(x => x.id == item.id);
                        var params = sco.params;

                        var score = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.vms_absolute_score',
                            value: (item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score') ? 
                            item.cmi_params.find((x) => x.element === 'cmi.vms_absolute_score').value : null)
                        };

                        var status = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.core.lesson_status',
                            value: item.status
                        };

                        var details = {
                            activity: sco.id,
                            type: params.idevice_type,
                            content: params.title_id,
                            name: params.sco_name,
                            passage_type: params.passage_type,
                            layout: params.layout,
                            order: item.order,
                            attempt: item.attempt,
                            key: 'cmi.vms_quiz_xml',
                            value: item.details
                        };

                        tracks.push(score);
                        tracks.push(status);
                        tracks.push(details);

                        return tracks;

                    }, []);
                }

                vimas.sco.lms.tracksCache($tracks);
            }

            // find page by last id
            var last = vmGetLastOpenedPage(scos, lastPage);

            return $.when(last);
        };

        function vmGetLastAttemptedPage(progress, max_attempts) {

            // check if all items in last attempt is completed
            var completed = progress.filter(x => x.attempt == vimas.sco.lms.attempt)
                .every(vmIsActivityCompleted);

            // if all activities are completed
            // than start new attempt
            if (completed)
               
            // don't allow attempt
            // over maximum value
            if (max_attempts && vimas.sco.lms.attempt > max_attempts) {

                // trigger the no atempts event
                $(self).trigger('no_attempts', max_attempts);

                return {
                    attempt: max_attempts,
                    id: -1
                };

            } else if (completed) {

                return {
                    attempt: vimas.sco.lms.attempt,
                    id: null
                };

            } else {

                // inside max attempt
                // find activity
                // which belongs to latest attempt
                // and is not "completed"
                var last = progress.filter(x => x.attempt == vimas.sco.lms.attempt)
                    .filter(x => x.timemodified)
                    .sort((a, b) => b.timemodified - a.timemodified)
                    .shift();

                return {
                    attempt: vimas.sco.lms.attempt,
                    id: last ? last.id : null
                };
            };
        };

        function vmGetLastOpenedPage(scos, lastPage) {

            // try to get sco from list                                    
            var sco = scos.find(x => x.id == lastPage);

            // if not found or finish activity
            // than open first page
            sco = self.lastUsePage(sco, scos);

            return sco;
        };

        function vmGetNextIncomplete(progress, scos, id, attempt) {

            var sco = scos.find(x => x.id == id);
            var scoProgress = progress.find(x => x.id == id && x.attempt == attempt);

            if (vmIsActivityCompleted(scoProgress)) {

                return vmNextCalculate(sco, scos)
                    .then((item) => item ? vmGetNextIncomplete(progress, scos, item.id, attempt) : sco);

            } else {

                return $.when(sco);
            };
        };
    };

    // is activity incomplete
    function vmIsActivityCompleted(activity) {

        return !!activity && (activity.status == 'completed' || activity.status == 'passed' || activity.status == 'skipped');
    };

    // load sco by id
    function vmLoadSco(id) {

        // get sco by id
        var sco = _scos.first('id', id);

        // if not found
        // than open first page
        if (!sco)
            sco = _scos[0];

        // set current sco id
        vimas.sco.lms.sco = sco.id;

        if (sco.activity) {

            // if activity already initialized
            // than just start it
            sco.activity.run();

            $(self).trigger('loaded', sco);

        } else {

            // if no activity
            // than create one
            sco.activity = Idevice.postinit('sco_' + sco.id, sco.params);

            $(sco.activity).on('loaded', function (e) {

                $(self).trigger('loaded', sco);
            });
        };

        // set activity as selected
        _current = sco.id;
        _sco = sco;

        $(self).trigger('current', sco);

        // save last used activity
        if (sco.isactivity)
            _last = sco.id;

        self.last = _last;

    };

    function vmExit(dest) {

        let info = {dest: dest, sco: _sco}
        $(self).trigger('webplayer.exit', info);
    };

    function vmFinish() {

        $(self).trigger('webplayer.finish', { attemptId: vimas.sco.lms.attempt });
    }

    function vmOpen(page) {

        if (page)
            vmLoadSco(page);
    };

    function vmNextCalculate(current, scos) {

        let props = current.params.props;

        if (props && props.p_adaptive_schematic && props.p_adaptive_schematic.conditions) {

            return vimas.sco.lms.progress()
                .then(function (progress) {

                    let conditions = props.p_adaptive_schematic.conditions;
                    let condition = conditions.filter((condition) =>

                        condition.operation == '>' ||
                        condition.operation == '>=' ||
                        condition.operation == '<' ||
                        condition.operation == '<='

                    ).find(function (condition) {

                        // calculate sum of scores
                        // for activities
                        var sum = condition.scoIds.reduce(function (sum, idnumber) {

                            // find sco by idnumber
                            // find score for current page in current attempt
                            var sco = scos.find(x => x.idnumber == idnumber);
                            var score = progress.find(x => x.activity == sco.id && x.key == 'cmi.vms_absolute_score' && x.attempt == vimas.sco.lms.attempt);

                            return sum + parseInt(score.value || 0);

                        }, 0);

                        // check conditions
                        // depending on operation specified
                        if (condition.operation == '>' && sum > parseInt(condition.controlValue)) {

                            return true;

                        } else if (condition.operation == '>=' && sum >= parseInt(condition.controlValue)) {

                            return true;

                        } else if (condition.operation == '<' && sum < parseInt(condition.controlValue)) {

                            return true;

                        } else if (condition.operation == '<=' && sum <= parseInt(condition.controlValue)) {

                            return true;

                        } else {

                            return false;
                        }
                    });

                    // find all next and random conditions
                    let random = conditions.filter((x) => x.operation == 'rnd');
                    let next = conditions.find((x) => x.operation == 'nxt');

                    // if condition was met than use it
                    // else select one of the random
                    // else move by next condition
                    if (condition) {

                        return condition.result;

                    } else if (random.length > 0) {

                        // try to find activity
                        // which was already completed
                        // among the random set
                        let previous = random.map((item) => {

                            let sco = scos.find(x => x.idnumber == item.result);
                            let status = progress.find((x) => x.activity == sco.id && x.key == 'cmi.core.lesson_status' && x.attempt == vimas.sco.lms.attempt);
                            item.status = status ? status.value : null;
                            return item.result;
                        })
                            .find((x) => x.status == 'completed');

                        // if previously completed activity
                        // is found than select it again
                        // else select random activity
                        if (!!previous) {

                            return previous.result;

                        } else {

                            // use API to get random path
                            // according to exposure rules
                            return vimas.sco.lms.random(current.idnumber, random)
                                .then((x) => x.idnumber)
                        };

                    } else if (next) {

                        return next.result;
                    };
                })
                .then((idnumber) => {

                    // if one of the conditions met
                    // than navigate to specified page
                    // otherwise just move to the next page
                    if (idnumber)
                        return scos.find(x => x.idnumber == idnumber);
                    else
                        return vmNextSequence(current, scos);
                })

        } else {

            var next = vmNextSequence(current, scos);
            return $.when(next);
        };
    };

    function vmNextSequence(current, scos) {

        var ind = scos.findIndex(x => x.id == current.id);

        var sco = scos[ind + 1];
        if (sco && sco.isactivity)
            return sco;
        else
            return null;
    };

    function vmNext() {

        var current = _scos.find(x => x.id == _current);

        vmNextCalculate(current, _scos)
            .then(function (next) {

                if (next) {
                   // $(self).trigger('webplayer.next', current);

                    vmOpen(next.id);
                } else {
                    $(self).trigger('no_actitity_already');
                }
            });
    };

    function vmPrevious() {

        var ind = _scos.findIndex(x => x.id == _current);

        var sco = _scos[ind - 1];
        if (sco && sco.isactivity)
            vmOpen(sco.id);
    };

    this.load = vmLoad;
    this.loadForTask = vmLoadForTask;
    this.open = vmOpen;
    this.next = vmNext;
    this.previous = vmPrevious;
    this.exit = vmExit;
};

vimas.sco.webcourse = function (args) {

    var self = this;
    var player = new vimas.sco.webplayer(args);
    var current = null;

    player.prepare = vmPrepare;
    player.lastUsePage = vmLastUsePage;

    self.load = player.load;
    self.loadForTask = player.loadForTask;
    self.exit = player.exit;
    self.open = player.open;
    self.next = player.next;
    self.previous = player.previous;
    self.stop = vmStop;

    $(player).on('prepared', function (e, data) {

        $(self).trigger('prepared', data);
    });

    $(player).on('current', function (e, sco) {

        current = sco;
        $(self).trigger('current', sco);
    });

    $(player).on('no_actitity_already', function (e) {

        $(self).trigger('no_actitity_already');
    });

    $(player).on('access_denied', function (e) {

        $(self).trigger('access_denied');
    });

    $(player).on('no_attempts', function (e, data) {

        $(self).trigger('no_attempts');
    });

    $(player).on('webplayer.start', function (e, data) {

        $(self).trigger('webcourse.start', data);

        // get last accessed read course
        var lastCourse = window.localStorage.getItem('languabooks.com/lastcourse');
        // if last course is present
        // than this means prev course
        // was exited incorrectly
        // so let's save it's results now
        if (lastCourse){
            vimas.sco.lms.vmApiCourseAttemptscores(data.sco.params); 
        }
        // save current course
        // as last one
        window.localStorage.setItem('languabooks.com/lastcourse', data.course);
        
    });

    $(player).on('webplayer.finish', function (e, data) {

        $(self).trigger('webcourse.finish', data);
    });

    $(player).on('webplayer.exit', function (e, info) {

        switch (info.dest) {

            case 'index':
            case 'glossary':
            case 'miccheck':

                player.open(info.dest);

                vimas.sco.lms.vmApiCourseAttemptscores(info.sco.params).then(function() {
                    $(self).trigger('webcourse.exit', info.dest);
                });               

                break;

            case 'page':

                player.open(player.last);

                break;

            default:

                player.open(null);

                vimas.sco.lms.vmApiCourseAttemptscores(info.sco.params).then(function() {
                    $(self).trigger('webcourse.exit', info.dest);
                });  

                break;
        };
    });

    // create mic check param set
    function vmMicCheckParams(config, course_mode, model) {

        var param = $.extend({}, config);

        // set default params for microphone check
        param.idevice_type = 'MCCA';
        param.title_id = 'G00000';
        param.default_difficulty_level_file = 'miccheck.xml';
        param.default_difficulty_level_index = '1';
        param.recoring_time_limit = '00:10';
        param.ss_model = model;
        param.course_mode = course_mode;
        param.layout = 'standalone';

        return param;
    };

    // create index param set
    function vmIndexParams(config, scos) {

        vimas.sco.lms.vmIndex = vmGetIndexData;
        vimas.sco.lms.vmOpen = player.open;

        var param = $.extend({}, config);

        // set default params for index page
        param.idevice_type = 'index';
        param.scos = scos;

        return param;

        // load index data
        function vmGetIndexData() {

            var def = $.Deferred();

            // get activities state and scores
            vimas.sco.lms.progress()
                .then(function (list) {

                    var data1 = list.filter(function (item) {

                        return item.key === 'cmi.vms_absolute_score';
                    });

                    var items = data1.reduce(function (p, n) {

                        var c = p.find(x => x.id === n.activity);

                        if (!c) {
                            c = {};
                            p.push(c);
                        };

                        c.id = n.activity;
                        c.type = n.type;
                        c.content = n.content;
                        c.order = n.order;
                        c.name = n.name;
                        c.passage_type = n.passage_type;
                        c.layout = n.layout;

                        if (n.attempt == vimas.sco.lms.attempt && n.key === 'cmi.vms_absolute_score')
                            c.score = n.value;

                        if (n.attempt == vimas.sco.lms.attempt && n.key === 'cmi.core.lesson_status')
                            c.status = n.value;

                        if (n.attempt == vimas.sco.lms.attempt && n.key === 'cmi.vms_absolute_score') {

                            var parsedNewValue = parseInt(n.value);

                            if (!isNaN(parsedNewValue)) {
                                c.bestscore = parsedNewValue;
                            }
                        }

                        return p;

                    }, []);
                    def.resolve(items);
                });

            return def.promise();
        };
    };

    // create glossary param set
    function vmGlossaryParams(config, scos) {

        vimas.sco.lms.vmGlossary = vmGetGlossaryData;
        vimas.sco.lms.vmOpen = player.open;

        var param = $.extend({}, config);

        // set default params for glossary page
        param.idevice_type = 'glossary';

        return param;

        //load glossary data
        function vmGetGlossaryData() {

            var data = scos.filter(function (sco) {

                if (['SRS', 'SAS', 'FCR', 'FCA', 'DGR'].contains(sco.type))
                    return sco
            });

            return $.when(data);
        }
    }

    // update default preload settings
    // specially for web player
    function vmPrepare(scos, args) {

        var config = $.extend({}, args.config.sco_defaults, args.course.skin);
        config.lang = args.lang;
        config.course_lang = args.course_lang;

        var finish_sco = scos.find((x)=>x.type == 'FC');
        if (finish_sco) {//for internal "index page"
            finish_sco.params.scos = scos;
        }

        var cwp_sco = scos.find((x)=>x.type == 'CWP');
        if (cwp_sco) {//for internal "index page"
            cwp_sco.params.scos = scos;
        }

        var scos = scos.concat({
            id: 'miccheck',
            container: '#sco_miccheck',
            type: 'MCCA',
            isactivity: false,
            params: vmMicCheckParams(config, args.course.mode, args.course.ss_model)
        })
            .concat({
                id: 'index',
                container: '#sco_index',
                type: 'index',
                isactivity: false,
                params: vmIndexParams(config, scos)
            })
            .concat({
                id: 'glossary',
                container: '#sco_glossary',
                type: 'glossary',
                isactivity: false,
                params: vmGlossaryParams(config, scos)
            });

        return scos;
    }

    //handle opening of course
    //when the course was completely passed earlier
    function vmLastUsePage(sco, scos) {

        if (!sco || sco.type == 'FC')
            sco = scos[0];

        return sco;
    };

    // stop current activity
    function vmStop() {

        //stop current activity
        current.activity.stop();
    };
}

vimas.sco.audiocourse = function (args) {

    var self = this;
    var player = new vimas.sco.webplayer(args);
    var current = null;

    // reset track URL
    // to prevent saving of the scores
    vimas.sco.lms.url.track = null;

    player.prepare = vmPrepare;
    player.lastUsePage = vmLastPageUse;

    self.load = player.load;
    self.open = player.open;
    self.next = player.next;
    self.previous = player.previous;
    self.exit = player.exit;
    self.timeout = args.timeout;
    self.stop = vmStop;

    $(player).on('prepared', function (e, data) {

        $(self).trigger('prepared', data)
    });

    $(player).on('current', function (e, sco) {

        current = sco;
        $(self).trigger('current', sco)
    });

    $(player).on('loaded', function (e, sco) {

        $(self).trigger('loaded', sco);
    });

    $(player).on('no_actitity_already', function (e) {

        $(self).trigger('no_actitity_already')
    });

    $(player).on('webplayer.exit', function (e, info) {

        player.open(null);

        $(self).trigger('audiocourse.exit', info.dest);
    });

    function vmPrepare(scos, args) {

        var scos = scos.filter(function (item) {

            if (item.type == 'SRS' ||
                item.type == 'SAS' ||
                item.type == 'FCR' ||
                item.type == 'FCA' ||
                (item.type == 'NSCO') &&
                (item.params.passage_type == 'Audio' || item.params.passage_type == 'ImageAudio')) {

                item.params.ss_model = args.course.ss_model;
                item.params.course_mode = args.course.mode;

                item.params.auto_play_passage_audio = 'No';
                item.params.layout += ' autoplay_course';
                item.params.playback_speed = 1;

                item.audioState = 'no_load';
                item.params.audio_mode = true;

                item.params.autoplay_delay = 0;

                return item;
            };
        });

        return scos;
    }

    //handle opening of audio course
    //when the course was completely passed earlier
    function vmLastPageUse(sco, scos) {

        var lastSco = scos[scos.length - 1];

        if (!sco || sco == lastSco)
            sco = scos[0];

        return sco;
    }

    // stop current activity
    function vmStop() {

        //stop current activity
        current.activity.stop();

        //then save activity events 
        current.activity.vpEvents.save();

        // remove audio player sunscription
        $(current.activity.vpPlayer).off('finish');
    };
}